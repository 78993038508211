import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

const bikes_limit = 5;
const countries = {
  Germany: "DE",
  Austria: "AT",
  Switzerland: "CH",
  Sweden: "SE",
  Norway: "NO",
  Finland: "FI",
  Netherlands: "NL",
  Belgium: "BE",
  Denmark: "DK",
  "United Kingdom": "GB",
  Spain: "ES",
  Portugal: "PT",
  Italy: "IT",
  France: "FR",
  Afghanistan: "AF",
  "Aland Islands": "AX",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bonaire, Saint Eustatius and Saba": "BQ",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Indian Ocean Territory": "IO",
  "British Virgin Islands": "VG",
  Brunei: "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas Island": "CX",
  "Cocos Islands": "CC",
  Colombia: "CO",
  Comoros: "KM",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Curacao: "CW",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  "Democratic Republic of the Congo": "CD",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  "East Timor": "TL",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Ethiopia: "ET",
  "Falkland Islands": "FK",
  "Faroe Islands": "FO",
  Fiji: "FJ",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Heard Island and McDonald Islands": "HM",
  Honduras: "HN",
  "Hong Kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR",
  Iraq: "IQ",
  Ireland: "IE",
  "Isle of Man": "IM",
  Israel: "IL",
  "Ivory Coast": "CI",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  Kosovo: "XK",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  Laos: "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macao: "MO",
  Macedonia: "MK",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  Micronesia: "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "North Korea": "KP",
  "Northern Mariana Islands": "MP",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  "Palestinian Territory": "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Pitcairn: "PN",
  Poland: "PL",
  "Puerto Rico": "PR",
  Qatar: "QA",
  "Republic of the Congo": "CG",
  Reunion: "RE",
  Romania: "RO",
  Russia: "RU",
  Rwanda: "RW",
  "Saint Barthelemy": "BL",
  "Saint Helena": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent and the Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  "Sint Maarten": "SX",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Georgia and the South Sandwich Islands": "GS",
  "South Korea": "KR",
  "South Sudan": "SS",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  "Svalbard and Jan Mayen": "SJ",
  Swaziland: "SZ",
  Syria: "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks and Caicos Islands": "TC",
  Tuvalu: "TV",
  "U.S. Virgin Islands": "VI",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United States": "US",
  "United States Minor Outlying Islands": "UM",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  Vatican: "VA",
  Venezuela: "VE",
  Vietnam: "VN",
  "Wallis and Futuna": "WF",
  "Western Sahara": "EH",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW",
};
const mobiles = [
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];

export default class extends Controller {
  
  static targets = ["locale", "locations", "bikeType", "bike_brand", "bike_type", "bike_model", "bike_teaser", "bike_expert", "bike_highlights", "bike_image", "bike_description", "day1", "days2", "days6", "days13"];

  static values = { basket: String };

  test() {
    url = "";
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("DATA", data);
      });
  }

  connect() {

    this.updateBasket();

    var urlParams;
    (window.onpopstate = function () {
      var match,
        pl = /\+/g, // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) {
          return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

      urlParams = {};
      while ((match = search.exec(query)))
        urlParams[decode(match[1])] = decode(match[2]);
    })();

    if (urlParams.model_type) {
      document.getElementById("type").value = urlParams.model_type;
    }

    if (urlParams.models && urlParams.models.length > 0) {
      const reset = document.getElementById("reset");
      if (reset) reset.classList.remove("hidden");
    }
    let plocId = document.getElementById("plocId");
    if (urlParams.plocId && plocId) {
      plocId.value = urlParams.plocId;
      let flag = false;
      let locations = this.locationsTarget;
      for (var i = 1; i < locations.options.length; i++) {
        locations.options[i].classList.remove("hidden");
        if (plocId.value && locations.options[i].dataset.island != plocId.value)
          locations.options[i].classList.add("hidden");
      }
    } else {
      let query = window.location.pathname.split("/");
      let island = false;
      if (plocId) {
        query.forEach((element) => {
          switch (element) {
            case "gran-canaria":
              island = true;
              plocId.value = "1";
              break;
            case "tenerife":
              island = true;
              plocId.value = "2";
              break;
            case "lanzarote":
              island = true;
              plocId.value = "3";
              break;
          }
        });
        if (!island) {
          plocId.value = 1;
        }
      }
    }
    if (urlParams.height) {
      document.getElementById("height").value = urlParams.height;
    }
    let active = document.getElementsByClassName("filter-type bg-black")[0];
    if (active) {
      active.classList.remove("bg-black");
      active.classList.add("bg-gray-300");
      let types = document.getElementsByClassName("filter-type");
      for (let i = 0; i < types.length; i++) {
        if (urlParams.model_type === types[i].dataset.type) {
          types[i].classList.remove("bg-gray-300");
          types[i].classList.add("bg-black");
        }
      }
    }
    let selected = false;
    let select = document.getElementById("country");
    if (select) {
      for (var key in countries) {
        let option = document.createElement("option");
        option.text = key;
        option.value = countries[key];
        if (option.value === "ES") {
          option.id = "es";
        }
        if (select.dataset.country === countries[key]) {
          option.selected = "selected";
          selected = true;
        }
        select.appendChild(option);
      } /*
      if ( !selected ) {
        let spain = document.getElementById('es');
        spain.setAttribute('selected', 'selected');
      }*/
    }
    let select2 = document.getElementById("prefix");
    if (select2) {
      selected = false;
      mobiles.forEach((prefix) => {
        let option = document.createElement("option");
        option.text = prefix.dial_code + " " + prefix.name;
        option.value = prefix.dial_code;
        if (prefix.code === "ES") {
          option.id = "es2";
        }
        if (select && select.dataset.country === prefix.code) {
          option.selected = "selected";
          selected = true;
        }
        select2.appendChild(option);
      });
      /*if ( !selected ) {
        let spain = document.getElementById('es2');
        spain.setAttribute('selected', 'selected');
      }*/
    }

    let layer = document.getElementById("bikes-booking");
    if (layer) this.bikes_layer();

    let bike = document.getElementById("single-bike");
    if (bike) this.single_bike_layer(bike);

    let anchor = document.getElementById("main-filters");
    let filterTagsMobile = document.getElementById("filterTagsMobile");
    if (filterTagsMobile) {
      document.addEventListener("scroll", (event) => {
        if (anchor.offsetTop + 278 <= window.scrollY)
          filterTagsMobile.classList.add("fixed-filters");
        else filterTagsMobile.classList.remove("fixed-filters");
      });
    }

    layer = null;
    layer = document.getElementById("cart");
    if (layer) document.addEventListener("priced", this.cart_layer);

    if (window.innerWidth < 640) {
      const dropdown = document.getElementById("previous-dropdown")
      if (dropdown) {
        dropdown.classList.remove('hidden')
        const input = document.getElementById("from")
        if (input) input.dataset.clicked = true;
      }
    }
  }

  filterShops(event) {
    const flag = document.getElementById("single-bike");
    var select = this.locationsTarget;
    for (var i = 1; i < select.options.length; i++) {
      select.options[i].classList.remove("hidden");
      if (
        event.target.value &&
        select.options[i].dataset.island != event.target.value
      )
        select.options[i].classList.add("hidden");
    }
    if (!flag) this.filterBikes();
    this.filterDays(document.getElementById('from'), event.target.value)
  }

  filterDays(input, island) {
    const today = new Date();
    const days = island === "3" ? 1 : 2;
    const minimumDays = document.getElementById('minimum-days') 
    today.setDate(today.getDate() + days);
    input.min = today.toISOString().split('T')[0];
    if (minimumDays) minimumDays.innerHTML = days
  }

  checkAdvanceDays() {
    const from = document.getElementById("from");
    const checkFrom = document.getElementById("error-from");
    const advance = document.getElementById('plocId');
    let days = 2;

    if (advance && advance.value === "3") {
        days = 1;
    }

    const min = new Date();
    min.setDate(min.getDate() + days);
    min.setHours(0, 0, 0, 0);

    const fromDate = new Date(from.value);
    fromDate.setHours(0, 0, 0, 0);

    if (!from.value || fromDate.getTime() < min.getTime()) {
        from.classList.add("error");
        if (checkFrom) checkFrom.classList.remove("hidden");
        return true;
    } else {
        from.classList.remove("error");
        if (checkFrom) checkFrom.classList.add("hidden");
        return false;
    }
  }


  selectIsland(event) {
    let place = document.getElementById("locations");
    place.classList.remove("error", "bg-red-100");
    const shops = this.locationsTarget;
    const shop = event.target.value;
    let select = document.getElementById("plocId");
    for (var i = 1; i < shops.options.length; i++) {
      if (shops.options[i].value === shop) {
        select.value = shops.options[i].dataset.island;
        break;
      }
    }
    for (var i = 1; i < shops.options.length; i++) {
      shops.options[i].classList.remove("hidden");
      if (select.value && shops.options[i].dataset.island != select.value)
        shops.options[i].classList.add("hidden");
    }
  }

  setToDate(event) {
    let from = new Date(event.target.value);
    let tmp = new Date(from);
    let to = document.getElementById("to");
    let min = this.check_season(from);

    tmp.setDate(from.getDate() + min);
    tmp = tmp.toISOString().split("T")[0];
    to.setAttribute("min", tmp);
    if (!to.value || to.value < tmp) to.value = tmp;
  }

  showDropdown(event) {
    const dropdown = document.getElementById('period-dropdown')
    const min = this.check_season(new Date(event.target.value));
    if (min == 2 && dropdown) dropdown.classList.remove('hidden')
  }

  showPreviousDropdown(event) {
    const input = event.currentTarget;
    if (!input.dataset.clicked) {
      const dropdown = document.getElementById("previous-dropdown");
      if (dropdown) dropdown.classList.remove("hidden");
      input.dataset.clicked = true;
    }
  }

  closeDropdown(event) {
    const id = event.target.dataset.dropdown
    const dropdown = document.getElementById(id)
    if (dropdown) dropdown.classList.add('hidden')
  }

  verifyShops() {
    let that = this;
    setTimeout(() => {
      const from = document.getElementById("from").value;
      const fromDate = new Date(from);
      const to = document.getElementById("to").value;
      const toDate = new Date(to);
      const locale = document.getElementById("locale").value;
      let closed = "Closed";
      switch (locale) {
        case "es":
          closed = "Cerrada";
          break;
        case "de":
          closed = "Chlossen";
          break;
        case "en":
          closed = "Closed";
          break;
      }
      let locations = this.locationsTarget;
      if (!from) return;
      for (var i = 0; i < locations.options.length; i++) {
        if (locations.options[i].value !== "") {
          locations.options[i].removeAttribute("disabled");
          locations.options[i].classList.remove("bg-gray-200");
          const index = locations.options[i].innerHTML.indexOf(" " + closed);
          if (index != -1)
            locations.options[i].innerHTML = locations.options[
              i
            ].innerHTML.substring(0, index - 2);
          let url1 = `/shops/opening_hours?loc_id=${locations.options[i].value}&date=${from}&index=${i}`;
          let url2 = `/shops/opening_hours?loc_id=${locations.options[i].value}&date=${to}&index=${i}`;
          fetch(url1)
            .then((response) => response.json())
            .then((data) => {
              if (data.opening_hours === "closed") {
                const day = fromDate.toLocaleDateString(locale, {
                  weekday: "long",
                });
                const date =
                  day +
                  " " +
                  that.padTo2Digits(fromDate.getDate()) +
                  "." +
                  that.padTo2Digits(fromDate.getMonth() + 1);
                locations.options[data.index].setAttribute(
                  "disabled",
                  "disabled"
                );
                locations.options[data.index].classList.add("bg-gray-200");
                const index = locations.options[data.index].innerHTML.indexOf(
                  " " + closed
                );
                if (index == -1)
                  locations.options[data.index].innerHTML =
                    locations.options[data.index].innerHTML +
                    " ⚠ " +
                    closed +
                    " " +
                    date;
              }
              fetch(url2)
                .then((response) => response.json())
                .then((data) => {
                  if (data.opening_hours === "closed") {
                    const day = toDate.toLocaleDateString(locale, {
                      weekday: "long",
                    });
                    const date =
                      day +
                      " " +
                      that.padTo2Digits(toDate.getDate()) +
                      "." +
                      that.padTo2Digits(toDate.getMonth() + 1);
                    locations.options[data.index].setAttribute(
                      "disabled",
                      "disabled"
                    );
                    locations.options[data.index].classList.add("bg-gray-200");
                    const index = locations.options[
                      data.index
                    ].innerHTML.indexOf(" " + closed);
                    if (index == -1)
                      locations.options[data.index].innerHTML =
                        locations.options[data.index].innerHTML +
                        " ⚠ " +
                        closed +
                        " " +
                        date;
                    else
                      locations.options[data.index].innerHTML =
                        locations.options[data.index].innerHTML + " , " + date;
                  }
                });
            });
        }
      }
    }, 100);
  }

  checkBike(event) {
    let errors = false;
    let from = document.getElementById("from");
    let to = document.getElementById("to");
    let plocId = document.getElementById("plocId");
    let place = document.getElementById("locations");
    let height = document.getElementById("height");
    let featured = document.getElementById("featured");
    let checkTo = document.getElementById("error-to");
    let shop = document.getElementById("error-shop");
    let checkHeight = document.getElementById("error-height");
    let scroll = true;

    errors = this.checkAdvanceDays()

    const temp = this.check_season(new Date(from.value));

    min = new Date(from.value);
    min.setDate(new Date(from.value).getDate() + temp);
    min.setHours(0, 0, 0);

    if (!to.value || new Date(to.value).getTime() < min.getTime()) {
      to.classList.add("error");
      if (checkTo) checkTo.classList.remove("hidden");
      errors = true;
    } else {
      to.classList.remove("error");
      if (checkTo) checkTo.classList.add("hidden");
    }

    if (!plocId.value) {
      plocId.classList.add("error");
      errors = true;
    } else plocId.classList.remove("error");

    if (!place.value || place.options[place.selectedIndex].disabled) {
      place.classList.add("error", "bg-red-100");
      if (shop) shop.classList.remove("hidden");
      errors = true;
    } else {
      place.classList.remove("error", "bg-red-100");
      if (shop) shop.classList.add("hidden");
    }

    if (!height.value) {
      height.classList.add("error");
      if (checkHeight) checkHeight.classList.remove("hidden");
      errors = true;
    } else {
      height.classList.remove("error");
      if (checkHeight) checkHeight.classList.add("hidden");
    }

    if (errors) {
      if (scroll) {
        let filters = document.getElementById("main-filters");
        let topPos = filters.offsetTop;
        window.scrollTo(0, topPos);
      }
      return;
    } else this.filterBike(event);
  }

  checkBikes(event) {
    let doCheck = false;
    if (event.target.id == "availability") doCheck = true;
    let errors = false;
    let from = document.getElementById("from");
    let to = document.getElementById("to");
    let plocId = document.getElementById("plocId");
    let place = document.getElementById("locations");
    let height = document.getElementById("height");
    let featured = document.getElementById("featured");
    let filterTags = document.getElementById("filterTags");
    let filterTagsMobile = document.getElementById("filterTagsMobile");
    let availability = document.getElementById("availability");
    let checkTo = document.getElementById("error-to");
    let shop = document.getElementById("error-shop");
    let checkHeight = document.getElementById("error-height");
    let scroll = true;
    let min;

    errors = this.checkAdvanceDays()

    const temp = this.check_season(new Date(from.value));
    min = new Date(from.value);
    min.setDate(new Date(from.value).getDate() + temp);
    min.setHours(0, 0, 0);

    if (!to.value || new Date(to.value).getTime() < min.getTime()) {
      to.classList.add("error");
      if (checkTo) checkTo.classList.remove("hidden");
      errors = true;
    } else {
      to.classList.remove("error");
      if (checkTo) checkTo.classList.add("hidden");
    }

    if (!plocId.value) {
      plocId.classList.add("error");
      errors = true;
    } else plocId.classList.remove("error");

    if (!place.value || place.options[place.selectedIndex].disabled) {
      place.classList.add("error", "bg-red-100");
      if (shop) shop.classList.remove("hidden");
      errors = true;
    } else {
      place.classList.remove("error", "bg-red-100");
      if (shop) shop.classList.add("hidden");
    }

    if (!height.value) {
      height.classList.add("error");
      if (checkHeight) checkHeight.classList.remove("hidden");
      errors = true;
    } else {
      height.classList.remove("error");
      if (checkHeight) checkHeight.classList.add("hidden");
    }

    let checks = document.querySelectorAll(".model:checked");
    let modelsNumber = document.querySelectorAll(".model").length;

    if (!errors && checks.length === 0 && modelsNumber > bikes_limit) {
      featured.classList.remove("hidden");
      errors = true;
      scroll = false;
    }

    if (errors) {
      if (scroll) {
        let filters = document.getElementById("main-filters");
        let topPos = filters.offsetTop;
        window.scrollTo(0, topPos);
      }
      return;
    } else this.filterBikes(event, doCheck);
  }

  closeAlert(event) {
    document.getElementById("featured").classList.add("hidden");
  }

  toggleLogin(event) {
    let elem = document.getElementById("collapse");
    let elem2 = document.getElementById("session");
    elem.classList.toggle("hidden");
    elem2.classList.toggle("hidden");
  }

  toggleTags(event) {
    let container = document.getElementById("filterWrapper");
    let chevron = document.getElementById("filterChevron");
    if (container.classList.contains("lg:block")) {
      container.classList.remove("lg:block", "hidden");
      chevron.classList.remove("lg:rotate-180");
    }
    if (container.classList.contains("hidden")) {
      container.classList.remove("hidden");
      chevron.classList.add("rotate-180");
    } else {
      container.classList.add("hidden");
      chevron.classList.remove("rotate-180");
    }
  }

  filterBike(event) {
    var bike_type = document.getElementById("type").value;
    var from = document.getElementById("from").value;
    var to = document.getElementById("to").value;
    //var from_diff = document.getElementById('from_variation').value;
    //var to_diff = document.getElementById('to_variation').value;
    var plocId = document.getElementById("plocId").value;
    var place = document.getElementById("locations").value;
    var height = document.getElementById("height").value;
    let availability = document.getElementById("availability");
    let bike = document.getElementById("single-bike");

    let models = bike.dataset.model;

    const lang = this.localeTarget.value;
    var url = `/${lang}/booking_process/finder/bikes?models=${models}&model_type=${bike_type}&from=${from}&to=${to}&plocId=${plocId}&place=${place}&height=${height}`;

    document.getElementById("spinner").classList.remove("hidden");

    availability.classList.add("hidden");

    let results = document.getElementById("results");
    let topPos = results.offsetTop;
    window.scrollTo(0, topPos);

    Turbo.visit(url, { action: "replace" });
  }

  filterBikes(event, doCheck = false) {
    var bike_type = document.getElementById("type").value;
    var from = document.getElementById("from").value;
    var to = document.getElementById("to").value;
    //var from_diff = document.getElementById('from_variation').value;
    //var to_diff = document.getElementById('to_variation').value;
    var plocId = document.getElementById("plocId").value;
    var place = document.getElementById("locations").value;
    var height = document.getElementById("height").value;
    var tagsContainer = document.getElementsByClassName("filterTag");
    let brands = document.querySelectorAll(".brand:checked");
    let availability = document.getElementById("availability");
    let filterTags = document.getElementById("filterTags");
    let filterTagsMobile = document.getElementById("filterTagsMobile");
    let brand = [];

    setTimeout(() => {
      if (brands.length > 0) {
        for (let i = 0; i < brands.length; i++) {
          brand.push(brands[i].value);
        }
      } else brand = "";

      let checks = document.querySelectorAll(".model:checked");
      let modelsNumber = document.querySelectorAll(".model").length;

      if (modelsNumber < (bikes_limit + 1) && doCheck)
        checks = document.querySelectorAll(".model");

      let models = "";

      for (let i = 0; i < checks.length; i++) {
        models += checks[i].value;
        if (i !== checks.length - 1) models += ",";
      }

      const lang = this.localeTarget.value;
      var url = `/${lang}/booking_process/finder/bikes?models=${models}&model_type=${bike_type}&from=${from}&to=${to}&plocId=${plocId}&place=${place}&height=${height}`;

      var tags = [];

      for (var i = 0; i < tagsContainer.length; i++) {
        if (tagsContainer[i].checked) tags.push(tagsContainer[i].value);
      }

      if (brand && brand.length > 0) url = url + `&brand=${brand}`;

      if (tags.length > 0) url = url + `&tags=${tags}`;

      document.getElementById("spinner").classList.remove("hidden");
      document.getElementById("reset").classList.remove("hidden");
      if (checks.length > 0) {
        let container = document.getElementById("filterWrapper");
        let chevron = document.getElementById("filterChevron");
        if (filterTags) filterTags.classList.add("hidden");
        if (filterTagsMobile) filterTagsMobile.classList.add("hidden");
        if (container) container.classList.add("md:block", "hidden");
        chevron.classList.add("md:rotate-180");
        availability.classList.add("hidden");
      } else {
        availability.classList.remove("hidden");
        if (filterTags) filterTags.classList.remove("hidden");
        if (filterTagsMobile) filterTagsMobile.classList.remove("hidden");
      }

      let results = document.getElementById("results");
      let topPos = results.offsetTop;
      window.scrollTo(0, topPos);

      Turbo.visit(url, { action: "replace" });
      let interval = setInterval(() => {
        if (document.getElementById("spinner").classList.contains("hidden")) {
          clearInterval(interval);
          this.bikes_layer();
        }
      }, 500);
    }, 1000);
  }

  resetBikes(event) {
    let brands = document.querySelectorAll(".brand:checked");
    let tagsContainer = document.getElementsByClassName("filterTag");
    if (brands.length > 0) {
      for (let i = 0; i < brands.length; i++) {
        brands[i].checked = false;
      }
    }
    for (var i = 0; i < tagsContainer.length; i++) {
      if (tagsContainer[i].checked) tagsContainer[i].checked = false;
    }
    this.filterBikes();
  }

  changeBikes(event) {
    let plocId = document.getElementById("plocId");
    let island = 'gran-canaria';
    switch (plocId.value) {
      case '1':
        island = 'gran-canaria';
        break;
      case '2':
        island = "tenerife";
        break;
      case '3':
        island = "lanzarote";
        break;
    }
    const lang = this.localeTarget.value;
    window.location.href = `/${lang}/${island}/bikes?model_type=${event.target.value}`;
  }

  selectBike(event) {
    let checks = document.querySelectorAll(".model:checked");
    let unchecks = document.querySelectorAll(".model:not(:checked)");
    if (checks.length === bikes_limit)
      for (let i = 0; i < unchecks.length; i++) {
        unchecks[i].disabled = true;
        unchecks[i].classList.add(
          "cursor-not-allowed",
          "bg-gray-100",
          "opacity-50"
        );
      }
    else
      for (let i = 0; i < unchecks.length; i++) {
        unchecks[i].disabled = false;
        unchecks[i].classList.remove(
          "cursor-not-allowed",
          "bg-gray-100",
          "opacity-50"
        );
      }
  }

  pickBike(event) {
    let bike = null;
    let texts = [];
    let image = [];
    let price;
    let prices;
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((el) => {
      if (el.dataset && el.dataset.bike) {
        bike = JSON.parse(el.dataset.bike);
        prices = JSON.parse(el.dataset.prices);
        price = el.dataset.price;
        texts = JSON.parse(el.dataset.texts);
        image = el.dataset.image;
      }
    });
    this.bike_brandTarget.src = `/fileadmin/website/files/manufacturer/${bike.manufacturer.toLowerCase()}.png`;
    this.bike_brandTarget.alt = bike.manufacturer;
    /*if ( bike.category_name == 'n/a' )
      this.bike_typeTarget.innerHTML = '';
    else
      this.bike_typeTarget.innerHTML = bike.category_name;*/
    //this.bike_teaserTarget.innerHTML = texts.teaser;
    this.bike_modelTarget.innerHTML = texts.title;
    this.bike_expertTarget.innerHTML = texts.teaser_expert;
    this.bike_highlightsTarget.innerHTML = texts.highlights;
    this.bike_highlightsTarget.children[0].classList.add("list-disc", "pl-5");
    this.bike_descriptionTarget.innerHTML = texts.description;
    if (prices && prices.length > 3) {
      this.day1Target.innerHTML = "€ " + prices[0].price;
      this.days2Target.innerHTML = "€ " + prices[1].price;
      this.days6Target.innerHTML = "€ " + prices[2].price;
      this.days13Target.innerHTML = "€ " + prices[3].price;
      document.getElementById("price-row").classList.remove("hidden");
      document.getElementById("price-explanation").classList.remove("hidden");
    } else {
      document.getElementById("price-row").classList.add("hidden");
      document.getElementById("price-explanation").classList.add("hidden");
    }
    let tmp = this.bike_descriptionTarget.children[0];
    if (tmp && tmp.nodeName === "B") tmp.classList.add("sm:text-2xl");
    this.bike_imageTarget.setAttribute("src", image);
    tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }

    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_name: "Bikes",
        items: [
          {
            item_id: bike.model_id.toString(),
            item_name: bike.model_title,
            currency: "EUR",
            index: 0,
            item_brand: bike.manufacturer,
            item_category: bike.product_group_name,
            price: parseFloat(price),
            quantity: 1,
          },
        ],
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - select_item", dataLayer);
  }

  showBike(event) {
    let errors = false;
    var bike = JSON.parse(event.target.dataset.bike);
    var price = event.target.dataset.price;
    var unit = event.target.dataset.unit;
    var from = document.getElementById("from");
    var to = document.getElementById("to");
    var height = document.getElementById("height");
    var place = document.getElementById("locations");
    var shop_name = place.options[place.options.selectedIndex].innerHTML;
    let checkTo = document.getElementById("error-to");
    let shop = document.getElementById("error-shop");
    let checkHeight = document.getElementById("error-height");

    const lang = this.localeTarget.value;

    if (!place.value || place.options[place.selectedIndex].disabled) {
      errors = true;
      place.classList.add("error");
      if (shop) shop.classList.remove("hidden");
    } else {
      place.classList.remove("error");
      if (shop) shop.classList.add("hidden");
    }

    errors = this.checkAdvanceDays()

    const temp = this.check_season(new Date(from.value));
    let tmp = new Date(from.value);
    tmp.setDate(tmp.getDate() + temp);
    if (tmp.getTime() > new Date(to.value).getTime()) {
      document.getElementById("to").classList.add("error");
      if (checkTo) checkTo.classList.remove("hidden");
      errors = true;
    } else {
      document.getElementById("to").classList.remove("error");
      if (checkTo) checkTo.classList.add("hidden");
    }

    if (!errors) {
      tmp = document.getElementById("user-info");
      let user = {};
      if (tmp) {
        tmp = tmp.dataset;
        user = {
          email: tmp.email,
          first_name: tmp.first_name,
          last_name: tmp.last_name,
          phone_number: tmp.phone_number,
          street: tmp.street,
          city: tmp.city,
          postal_code: tmp.postal_code,
          country: tmp.country,
        };
      }
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: "select_item",
        ecommerce: {
          item_list_name: "Bikes",
          items: [
            {
              item_id: bike.model_id.toString(),
              item_name: bike.model_title,
              currency: "EUR",
              index: 0,
              item_brand: bike.manufacturer,
              item_category: bike.product_group_name,
              price: parseFloat(price),
              quantity: 1,
            },
          ],
          enhanced_conversion_data: user,
        },
      });
      from = event.target.dataset.from;
      to = event.target.dataset.to;
      console.log("DATALAYER - select_item", dataLayer);
      document.getElementById("spinner_modal").classList.remove("hidden");
      document.getElementById("modal").classList.remove("hidden");
      let url = `/${lang}/booking_process/modal/bike?model=${bike.model_id}&lang=en&from=${from}&to=${to}&place=${place.value}&height=${height.value}&shop_name=${shop_name}&price=${price}}&unit=${unit}`;
      Turbo.visit(url, { action: "replace" });
    }
  }

  addToCart(event) {
    let button;
    let path = event.path || (event.composedPath && event.composedPath());
    for (let el of path) {
      if (el.dataset.model !== undefined && el.dataset.model !== "") {
        button = el;
        break;
      }
    };

    document.getElementById("addToCart").classList.remove("hidden");
    var errors = false;
    var name = document.getElementById("name");
    var surname = document.getElementById("surname");
    var weight = document.getElementById("weight");
    var height = document.getElementById("height_modal").value;
    var pedal = document.getElementById("pedal");
    var saddle = document.getElementById("saddle");
    var location = document.getElementById("locations");
    let bikeShop = document.getElementById("bikeShop");
    let checkTo = document.getElementById("error-to");
    let shop = document.getElementById("error-shop");
    let checkHeight = document.getElementById("error-height");
    var modelId = button.dataset.model;
    var total = button.dataset.total;
    var unit = button.dataset.unit;
    var from = document.getElementById("from");
    var to = document.getElementById("to");
    let model_name = button.dataset.name;
    let manufacturer = button.dataset.brand;
    let category = button.dataset.category;

    if (!location.value || location.options[location.selectedIndex].disabled) {
      errors = true;
      bikeShop.classList.add("text-red-500");
      if (shop) shop.classList.remove("hidden");
    } else {
      bikeShop.classList.remove("text-red-500");
      if (shop) shop.classList.add("hidden");
      location = location.value;
    }

    errors = this.checkAdvanceDays()

    const temp = this.check_season(new Date(from.value));
    let tmp = new Date(from.value);
    tmp.setDate(tmp.getDate() + temp);

    if (tmp > to.value) {
      errors = true;
      if (checkTo) checkTo.classList.remove("hidden");
    } else {
      if (checkTo) checkTo.classList.add("hidden");
    }

    if (errors) document.getElementById("modal").classList.add("hidden");

    if (!name.value) {
      name.classList.add("error");
      errors = true;
    } else name.classList.remove("error");

    if (!surname.value) {
      surname.classList.add("error");
      errors = true;
    } else surname.classList.remove("error");

    if (!weight.value) {
      weight.classList.add("error");
      errors = true;
    } else weight.classList.remove("error");

    if (!pedal.value) {
      pedal.classList.add("error");
      errors = true;
    } else pedal.classList.remove("error");

    if (errors) return true;

    name = name.value;
    surname = surname.value;
    from = button.dataset.from;
    to = button.dataset.to;

    var url = `/booking_process/add_to_basket?name=${name}&surname=${surname}&weight=${weight.value}&height=${height}&pedal=${pedal.value}&modelId=${modelId}&loc=${location}&total=${total}&unit=${unit}&from=${from}&to=${to}`;

    if (saddle.value) {
      saddle = saddle.value;
      url += `&saddle=${saddle}`;
    }

    const url2 = url;

    var basket = this.basketValue

    if (basket && basket !== "undefined") url += `&basket=${basket}`;

    document.getElementById("addToCart").classList.add("hidden");
    document.getElementById("spinner-text").classList.remove("hidden");
    document.getElementById("spinner_modal").classList.remove("hidden");

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (!data || data.unavailable) {
          document.getElementById("unavailable").classList.remove("hidden");
          document.getElementById("spinner_modal").classList.add("hidden");
          document.getElementById("spinner-text").classList.add("hidden");
        } else {
          const start = new Date(from);
          const end = new Date(to);
          const qty =
            Math.ceil(
              (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
            ) + 1;
          let items = [
            {
              item_id: modelId.toString(),
              item_name: model_name,
              index: 0,
              item_brand: manufacturer,
              item_category: category,
              location_id: location,
              price: parseFloat(unit),
              quantity: qty,
            },
          ];
          let insuprice = data.insurance.price;
          if (insuprice % 1 !== 0) insuprice = data.insurance.price.toFixed(2);
          this.addToCart_layer(total, items);
          document.getElementById("continue").classList.remove("hidden");
          //document.getElementById('close').classList.remove("hidden");
          this.updateBasket();
          document.getElementById("spinner_modal").classList.add("hidden");
          document.getElementById("spinner-text").classList.add("hidden");
          document.getElementById("insumage").alt = data.insurance.name;
          document.getElementById("insuname").innerHTML = data.insurance.name;
          document.getElementById("insudays").innerHTML = data.count;
          document.getElementById("insucheck").dataset.bike = data.bikeId;
          document.getElementById("insucheck").dataset.item = JSON.stringify(
            data.insurance
          );
          document.getElementById("insucheck").value = data.insurance.prodId;
          document.getElementById("insuprice").innerHTML = insuprice;
          document.getElementById("insudesc").innerHTML =
            data.insurance.longDescription;
          document.getElementById("insurance").classList.remove("hidden");
        }
      })
      .catch((error) => {
        fetch(url2)
          .then((response) => response.json())
          .then((data) => {
            const start = new Date(from);
            const end = new Date(to);
            const qty =
              Math.ceil(
                (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
              ) + 1;
            let items = [
              {
                item_id: modelId.toString(),
                item_name: model_name,
                index: 0,
                item_brand: manufacturer,
                item_category: category,
                location_id: location,
                price: parseFloat(unit),
                quantity: qty,
              },
            ];
            this.addToCart_layer(total, items);
            let insuprice = data.insurance.price;
            if (insuprice % 1 !== 0)
              insuprice = data.insurance.price.toFixed(2);
            document.getElementById("continue").classList.remove("hidden");
            //document.getElementById('close').classList.remove("hidden");
            document.getElementById("spinner_modal").classList.add("hidden");
            this.updateBasket();
            document.getElementById("insumage").alt = data.insurance.name;
            document.getElementById("insuname").innerHTML = data.insurance.name;
            document.getElementById("insudays").innerHTML = data.count;
            document.getElementById(
              "insudates"
            ).innerHTML = `${data.from} - ${data.to}`;
            document.getElementById("insucheck").dataset.bike = data.bikeId;
            document.getElementById("insucheck").value = data.insurance.prodId;
            document.getElementById("insuprice").innerHTML = insuprice;
            document.getElementById("insurance").classList.remove("hidden");
          });
      });
  }

  addToCartAgent(event) {
    document.getElementById("addToCart").classList.remove("hidden");
    var errors = false;
    var name = document.getElementById("name");
    var surname = document.getElementById("surname");
    var weight = document.getElementById("weight");
    var height = document.getElementById("height_modal").value;
    var pedal = document.getElementById("pedal");
    var saddle = document.getElementById("saddle");
    var location = document.getElementById("locations");
    let bikeShop = document.getElementById("bikeShop");
    let checkTo = document.getElementById("error-to");
    let shop = document.getElementById("error-shop");
    let checkHeight = document.getElementById("error-height");
    var modelId = event.target.dataset.model;
    var total = event.target.dataset.total;
    var unit = event.target.dataset.unit;
    var from = document.getElementById("from");
    var to = document.getElementById("to");
    let model_name = event.target.dataset.name;
    let manufacturer = event.target.dataset.brand;
    let category = event.target.dataset.category;
    let mail = document.getElementById("rider-mail");
    let phone = document.getElementById("rider-phone");

    if (!location.value || location.options[location.selectedIndex].disabled) {
      errors = true;
      bikeShop.classList.add("text-red-500");
      if (shop) shop.classList.remove("hidden");
    } else {
      bikeShop.classList.remove("text-red-500");
      if (shop) shop.classList.add("hidden");
      location = location.value;
    }

    
    
    const temp = this.check_season(new Date(from.value));
    let tmp = new Date(from.value);
    tmp.setDate(tmp.getDate() + temp);

    if (tmp > to.value) {
      errors = true;
      if (checkTo) checkTo.classList.remove("hidden");
    } else {
      if (checkTo) checkTo.classList.add("hidden");
    }

    if (errors) document.getElementById("modal").classList.add("hidden");

    if (!name.value) {
      name.classList.add("error");
      errors = true;
    } else name.classList.remove("error");

    if (!surname.value) {
      surname.classList.add("error");
      errors = true;
    } else surname.classList.remove("error");

    if (!weight.value) {
      weight.classList.add("error");
      errors = true;
    } else weight.classList.remove("error");

    if (!pedal.value) {
      pedal.classList.add("error");
      errors = true;
    } else pedal.classList.remove("error");

    if (!mail.value) {
      mail.classList.add("error");
      errors = true;
    } else mail.classList.remove("error");

    if (!phone.value) {
      phone.classList.add("error");
      errors = true;
    } else phone.classList.remove("error");

    if (errors) return true;

    name = name.value;
    surname = surname.value;
    phone = phone.value;
    mail = mail.value;
    from = event.target.dataset.from;
    to = event.target.dataset.to;

    var url = `/booking_process/agents/add_to_basket?name=${name}&surname=${surname}&weight=${weight.value}&height=${height}&pedal=${pedal.value}&modelId=${modelId}&loc=${location}&total=${total}&unit=${unit}&from=${from}&to=${to}&mail=${mail}&phone=${phone}`;

    if (saddle.value) {
      saddle = saddle.value;
      url += `&saddle=${saddle}`;
    }

    document.getElementById("addToCart").classList.add("hidden");
    document.getElementById("spinner-text").classList.remove("hidden");
    document.getElementById("spinner_modal").classList.remove("hidden");

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (!data || data.unavailable) {
          document.getElementById("unavailable").classList.remove("hidden");
          document.getElementById("spinner-text").classList.add("hidden");
          document.getElementById("spinner_modal").classList.add("hidden");
        } else {
          let single_basket = data.basket;
          const start = new Date(from);
          const end = new Date(to);
          const qty =
            Math.ceil(
              (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
            ) + 1;
          let items = [
            {
              item_id: modelId.toString(),
              item_name: model_name,
              index: 0,
              item_brand: manufacturer,
              item_category: category,
              location_id: location,
              price: parseFloat(unit),
              quantity: qty,
            },
          ];
          let insuprice = data.insurance.price;
          if (insuprice % 1 !== 0) insuprice = data.insurance.price.toFixed(2);
          this.addToCart_layer(total, items);
          document.getElementById("continue").classList.remove("hidden");
          //document.getElementById('close').classList.remove("hidden");

          let storedBasket = sessionStorage.getItem("baskets");
          let basketArray = storedBasket ? JSON.parse(storedBasket) : [];
          basketArray.push(data.basket);
          let updatedBasket = JSON.stringify(basketArray);
          sessionStorage.setItem("baskets", updatedBasket);

          document.getElementById("spinner_modal").classList.add("hidden");
          document.getElementById("spinner-text").classList.add("hidden");
          this.updateBasketAgent(single_basket);
          document.getElementById("insumage").alt = data.insurance.name;
          document.getElementById("insuname").innerHTML = data.insurance.name;
          document.getElementById("insudays").innerHTML = data.count;
          document.getElementById("insucheck").dataset.bike = data.bikeId;
          document.getElementById("insucheck").dataset.item = JSON.stringify(
            data.insurance
          );
          document.getElementById("insucheck").value = data.insurance.prodId;
          document.getElementById("insuprice").innerHTML = insuprice;
          document.getElementById("insudesc").innerHTML =
            data.insurance.longDescription;
          document.getElementById("insurance").classList.remove("hidden");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  step2(event) {
    let urlParams;
    (window.onpopstate = function () {
      var match,
        pl = /\+/g, // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) {
          return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

      urlParams = {};
      while ((match = search.exec(query)))
        urlParams[decode(match[1])] = decode(match[2]);
    })();
    let spinner = document.getElementById("spinner");
    if (spinner) spinner.classList.remove("hidden");
    let spinner_modal = document.getElementById("spinner_modal");
    if (spinner_modal) spinner_modal.classList.remove("hidden");
    document.addEventListener("turbo:before-fetch-response", function (e) {
      let selected = false;
      var interval = setInterval(function () {
        let select = document.getElementById("country");
        if (select) {
          clearInterval(interval);
          for (var key in countries) {
            var option = document.createElement("option");
            option.text = key;
            option.value = countries[key];
            if (option.value === "ES") {
              option.id = "es";
            }
            if (select.dataset.country === countries[key]) {
              option.selected = "selected";
              selected = true;
            }
            select.appendChild(option);
          }
          if (!selected) {
            let spain = document.getElementById("es");
            spain.setAttribute("selected", "selected");
          }
          let select2 = document.getElementById("prefix");
          if (select2) {
            selected = false;
            mobiles.forEach((prefix) => {
              let option = document.createElement("option");
              option.text = prefix.dial_code + " " + prefix.name;
              option.value = prefix.dial_code;
              if (prefix.code === "ES") {
                option.id = "es2";
              }
              if (select && select.dataset.country === prefix.code) {
                option.selected = "selected";
                selected = true;
              }
              select2.appendChild(option);
            });
            if (!selected) {
              let spain = document.getElementById("es2");
              spain.setAttribute("selected", "selected");
            }
          }
        }
      }, 100);
    });
    const lang = this.localeTarget.value;
    window.location.href = `/${lang}/basket`;
  }

  step2Agent(event) {
    let spinner = document.getElementById("spinner");
    if (spinner) spinner.classList.remove("hidden");
    let spinner_modal = document.getElementById("spinner_modal");
    if (spinner_modal) spinner_modal.classList.remove("hidden");
    const lang = this.localeTarget.value;
    window.location.href = `/${lang}/agents/baskets`;
  }

  step3(event) {
    let urlParams;
    (window.onpopstate = function () {
      var match,
        pl = /\+/g, // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) {
          return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

      urlParams = {};
      while ((match = search.exec(query)))
        urlParams[decode(match[1])] = decode(match[2]);
    })();
    if (!this.hasBasketValue || this.basketValue == "") window.location.href = "bikes";
    let checkbox = document.getElementById("account");
    let errors = false;
    let customer = document.forms["customer"].getElementsByTagName("input");
    let errorMsgs = document.forms["customer"].getElementsByTagName("p");
    let country = document.getElementById("country");
    let prefix = document.getElementById("prefix");
    const lang = this.localeTarget.value;
    let url2 = `/${lang}/booking_process/step3?`;
    for (let i = 1; i < customer.length; i++) {
      if (!customer[i].value) {
        customer[i].classList.add("error");
        errorMsgs[i - 1].classList.remove("hidden");
        errors = true;
      } else {
        customer[i].classList.remove("error");
        errorMsgs[i - 1].classList.add("hidden");
        url2 += `${customer[i].name}=${customer[i].value}&`;
      }
    }

    if (!prefix.value) {
      prefix.classList.add("error");
      document.getElementById("error-phone").classList.remove("hidden");
      errors = true;
    } else {
      prefix.classList.remove("error");
      document.getElementById("error-phone").classList.add("hidden");
      let tmp = "%2B" + prefix.value.slice(1);
      url2 += `prefix=${tmp}&`;
    }

    if (!country.value) {
      country.classList.add("error");
      document.getElementById("error-country").classList.remove("hidden");
      errors = true;
    } else {
      country.classList.remove("error");
      document.getElementById("error-country").classList.add("hidden");
      url2 += `country=${country.value}&`;
    }

    if (checkbox) {
      if (!checkbox.checked) {
        checkbox.classList.add("error");
        document.getElementById("error-account").classList.remove("hidden");
        errors = true;
      } else {
        checkbox.classList.remove("error");
        document.getElementById("error-account").classList.add("hidden");
      }
    }

    if (errors) {
      let contractor = document.getElementById("contractor");
      let topPos = contractor.offsetTop;
      window.scrollTo(0, topPos);
      return;
    }

    document.getElementById("spinner").classList.remove("hidden");
    const cart_layer = JSON.parse(sessionStorage.getItem("cart_layer"));
    if (cart_layer) {
      dataLayer.push({ ecommerce: null }); 
      dataLayer.push({
        event: "begin_checkout",
        ecommerce: { ...cart_layer },
      });
      console.log("DATALAYER - begin_checkout", dataLayer);
    }
    url2 = url2.slice(0, -1); 
    Turbo.visit(url2, { action: "replace" });

    /*fetch(`/booking_process/get_total?basket=${basket}`)
      .then(response => response.json())
      .then((data) => {
        if ( data.price >= 400 )
          if ( !vat.value ) {
            vat.classList.add('error');
            vatError.classList.remove('hidden');
            errors = true;
          }
          else {
            vat.classList.remove('error');
            vatError.classList.add('hidden');
            url2 += `&vatId=${vat.value}`;
          }

          if ( errors ) {
            document.getElementById('spinner').classList.add('hidden');
            return;
          }
          Turbo.visit(url2, { action: 'replace' });
      });*/
  }

  updateBasket() {
    if (document.getElementById("price_spinner")) document.getElementById("price_spinner").classList.remove("hidden");
    let basket = this.basketValue
    if (basket && basket != 'null') {
      const url = `/booking_process/get_basket/${basket}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let unavailable = document.getElementById("unavailable");
          if (unavailable) unavailable.classList.add("hidden");
          let count = 0;
          let price = 0;
          let discount = 0;
          let sum = 0;
          if (data) {
            data.OrderPosition.forEach((element) => {
              if (element.navItemNo != '145A')
                price = price + element.pricePerUnit;
              if (element.prodType == "R" || element.prodType == "T") count++;
              if (element.pricePerUnit < 0 && element.navItemNo != '145A')
                discount = discount + Math.abs(element.pricePerUnit);
            });
          }
          let supertotal = document.getElementById("supertotal");
          let superdiscount = document.getElementById("discount");
          let wrapper = document.getElementById("discount-wrapper");
          if (discount > 0) {
            sum = price + discount;
            sum = sum.toFixed(2);
            sum = sum.replace(".", ",");
            price = price.toFixed(2);
            price = price.replace(".", ",");
            discount = discount.toFixed(2);
            discount = discount.replace(".", ",");
            if (supertotal) {
              supertotal.innerHTML = sum + "€";
              supertotal.classList.remove("hidden");
            }
            if (superdiscount) {
              superdiscount.innerHTML = discount + "€";
              wrapper.classList.remove("hidden");
            }
          } else {
            if (supertotal) supertotal.classList.add("hidden");
            if (wrapper) wrapper.classList.add("hidden");
          }
          let total = document.getElementById("total");
          if (total) total.innerHTML = price;

          const event = document.createEvent("Event");
          event.initEvent("priced", true, true);
          document.dispatchEvent(event);
          document.getElementById("spinner").classList.add("hidden");
          if (document.getElementById("price_spinner"))
            document.getElementById("price_spinner").classList.add("hidden");
        });
    } else {
      document.getElementById("spinner").classList.add("hidden");
    }
  }

  updateBasketAgent(basket) {
    if (basket) {
      const url = `/booking_process/get_basket/${basket}`;
      let price = 0;
      let discount = 0;
      let sum = 0;
      let baskets = sessionStorage.getItem("baskets");
      let count = baskets ? JSON.parse(baskets).length : 0;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let unavailable = document.getElementById("unavailable");
          if (unavailable) unavailable.classList.add("hidden");
          if (data) {
            data.OrderPosition.forEach((element) => {
              price = price + element.pricePerUnit;
              if (element.prodType == "X")
                if (element.pricePerUnit < 0)
                  discount = discount + Math.abs(element.pricePerUnit);
            });
          }
          let supertotal = document.getElementById("supertotal");
          let superdiscount = document.getElementById("discount");
          let wrapper = document.getElementById("discount-wrapper");
          if (discount > 0) {
            sum = price + discount;
            sum = sum.toFixed(2);
            sum = sum.replace(".", ",");
            price = price.toFixed(2);
            price = price.replace(".", ",");
            discount = discount.toFixed(2);
            discount = discount.replace(".", ",");
            if (supertotal) {
              supertotal.innerHTML = sum + "€";
              supertotal.classList.remove("hidden");
            }
            if (superdiscount) {
              superdiscount.innerHTML = discount + "€";
              wrapper.classList.remove("hidden");
            }
          } else {
            if (supertotal) supertotal.classList.add("hidden");
            if (wrapper) wrapper.classList.add("hidden");
          }
          let total = document.getElementById("total");
          if (total) total.innerHTML = price;

          const event = document.createEvent("Event");
          event.initEvent("priced", true, true);
          document.dispatchEvent(event);
          document.getElementById("cart1").innerHTML = count;
          document.getElementById("cart2").innerHTML = count;
        });
    }
  }

  removeFromCart(event) {
    document.getElementById("spinner").classList.remove("hidden");
    let basket = this.basketValue
    let cancel = "";
    let tmp;
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((element) => {
      if (element.dataset && element.dataset.cancel) {
        cancel = element.dataset.cancel;
        tmp = JSON.parse(element.dataset.item);
      }
    });
    if (cancel.length > 0) {
      let items;
      if (tmp.modelId) {
        items = [
          {
            item_id: tmp.modelId.toString(),
            item_name: tmp.modelName,
            item_brand: tmp.manufacturer,
            item_category: tmp.category,
            location_id: tmp.plocId,
            price: parseFloat(tmp.pricePerUnit),
            quantity: tmp.unitCount,
          },
        ];
      } else {
        items = [
          {
            item_id: tmp.tourId.toString(),
            item_name: tmp.details.tourName,
            item_category: tmp.details.categoryName,
            location_id: tmp.details.locId,
            price: parseFloat(tmp.pricePerUnit),
            quantity: 1,
          },
        ];
      }
      let url = `/booking_process/remove_from_basket?res_id=${cancel}`;
      fetch(url).then((data) => {
        this.removeFromCart_layer(parseFloat(tmp.priceTotal), items);
        //this.updateBasket();
        window.location.reload();
      });
    }
  }

  toggleTour(event) {
    let basket = event.target.dataset.basket
      ? event.target.dataset.basket
      : this.basketValue
    const tour = event.target;
    const bike = tour.dataset.bike;
    var date = tour.dataset.date;
    const cancel = tour.dataset.cancel;
    let tours = document.querySelectorAll(`.tour-${bike}`);
    let tmp = JSON.parse(tour.dataset.item);
    let add;

    if (tour.checked) {
      tours.forEach((element) => {
        if (element.value != tour.value && date == element.dataset.date)
          element.setAttribute("disabled", "disabled");
      });
      add = true;
      var url = `/booking_process/add_tour_to_basket?tour=${tour.value}&bpr=${tour.dataset.bpr}&res=${bike}&loc=${tour.dataset.loc}&date=${date}`;
    } else {
      tours.forEach((element) => {
        if (element.value != tour.value && date == element.dataset.date)
          element.removeAttribute("disabled");
      });
      add = false;
      var url = `/booking_process/remove_from_basket?res_id=${cancel}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let items = [
          {
            item_id: tmp.tourId.toString(),
            item_name: tmp.details.tourName,
            item_category: tmp.details.categoryName,
            location_id: tmp.details.locId,
            price: parseFloat(tmp.price),
            quantity: 1,
          },
        ];
        if (add) this.addToCart_layer(parseFloat(tmp.price), items);
        else this.removeFromCart_layer(parseFloat(tmp.price), items);
        this.updateBasket();
        if (data && data.resId) event.target.dataset.cancel = data.resId;
        else delete event.target.dataset.cancel;
      });
  }

  toggleExtra(event) {
    let lang = "en";
    let basket = this.basketValue
    const extra = event.target;
    const cancel = extra.dataset.cancel;
    let resId = extra.dataset.bike;
    let tmp = JSON.parse(extra.dataset.item);
    let add;

    if (extra.checked) {
      add = true;
      var url = `/booking_process/add_extra_to_bike?basket=${basket}&res_id=${resId}&prod_id=${extra.value}&lang=${lang}`;
    } else {
      add = false;
      var url = `/booking_process/remove_from_basket?res_id=${cancel}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let items = [
          {
            item_id: tmp.prodId.toString(),
            item_name: tmp.title,
            currency: "EUR",
            item_category: tmp.prodType,
            price: parseFloat(tmp.price),
            quantity: 1
          },
        ];
        if (add) this.addToCart_layer(parseFloat(tmp.price), items);
        else this.removeFromCart_layer(parseFloat(tmp.price), items);
        this.updateBasket();
        if (data && data.resId) event.target.dataset.cancel = data.resId;
        else delete event.target.dataset.cancel;
      });
  }

  removeTour(event) {
    let basket = this.basketValue
    const tour = event.target;
    const cancel = tour.dataset.cancel;
    var url = `/booking_process/remove_from_basket?res_id=${cancel}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const container = document.getElementById("tour-" + cancel);
        container.parentElement.removeChild(container);
        this.updateBasket();
      });
  }

  removeExtra(event) {
    let basket = this.basketValue
    const extra = event.target;
    const cancel = extra.dataset.cancel;
    var url = `/booking_process/remove_from_basket?res_id=${cancel}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const container = document.getElementById("extra-" + cancel);
        container.parentElement.removeChild(container);
      });
  }

  payBooking() {
    let terms = document.getElementById("terms");
    let errorTerms = document.getElementById("error-terms");
    if (terms.checked) {
      errorTerms.classList.add('hidden');
      let basket = this.basketValue
      let comment = document.getElementById("comment").value;
      let url = `/booking_process/send_comment?basket=${basket}&comment=${comment}`;
      document.getElementById("spinner").classList.remove("hidden");
      document.getElementById("spinner-text").classList.remove("hidden");
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let url = `/booking_process/create-payment?basket=${basket}`;
          fetch(url)
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
              window.location = `https://www.vivapayments.com/web/checkout?ref=${data.ref}`;
            });
        });
    }
    else{
      errorTerms.classList.remove('hidden');
    }
  }

  closeBasket(event) {
    let terms = document.getElementById("terms");
    let errorTerms = document.getElementById("error-terms");
    if (terms.checked) {
      errorTerms.classList.add('hidden');
      let basket = this.basketValue
      let comment = document.getElementById("comment").value;
      let email = document.getElementById("user-mail").innerHTML;
      let url = `/booking_process/send_comment?basket=${basket}&comment=${comment}`;
      document.getElementById("spinner").classList.remove("hidden");
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let url = `/booking_process/close_basket?email=${email}`;
          fetch(url).then((response) => {
            let modal = document.getElementById("modal");
            modal.classList.remove("hidden");
            document.getElementById("spinner").classList.add("hidden");
          });
        });
    }
    else{
      errorTerms.classList.remove('hidden');
    }
  }

  closeUnavailable(event) {
    document.getElementById("unavailable").classList.add("hidden");
  }

  finish(event) {
    window.location.href = "/";
  }

  check_season(date) {
    const island = document.getElementById('plocId').value;
    let summer_start = document.getElementById("season_from");
    let summer_end = document.getElementById("season_to");
    let min = 2;

    if (summer_start && summer_end) {
      summer_start = new Date(summer_start.value);
      summer_end = new Date(summer_end.value);
      if (island != 1 || (date >= summer_start && date <= summer_end)) min = 0;
    }
    return min;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  closeModal() {
    document.getElementById("modal").classList.add("hidden");
  }

  resetFallBack() {
    fetch("/booking_process/reset_fallback").then((response) => {

    })
  }

  reload() {
    document.getElementById("spinner").classList.remove("hidden");
    location.reload();
  }

  showPasswordField() {
    document.getElementById("password-section").classList.toggle("hidden");
  }

  single_bike_layer(elem) {
    let bike;
    if (elem && elem.dataset) {
      bike = JSON.parse(elem.dataset.bike);
      let price = parseFloat(elem.dataset.price);
      let tmp = document.getElementById("user-info");
      let user = {};
      if (tmp) {
        tmp = tmp.dataset;
        user = {
          email: tmp.email,
          first_name: tmp.first_name,
          last_name: tmp.last_name,
          phone_number: tmp.phone_number,
          street: tmp.street,
          city: tmp.city,
          region: tmp.region,
          postal_code: tmp.postal_code,
          country: tmp.country,
        };
      }

      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          item_list_name: "Bikes",
          items: [
            {
              item_id: bike.model_id.toString(),
              item_name: bike.model_title,
              currency: "EUR",
              index: 0,
              item_brand: bike.manufacturer,
              item_category: bike.product_group_name,
              price: parseFloat(price),
              quantity: 1,
            },
          ],
          enhanced_conversion_data: user,
        },
      });
      console.log("DATALAYER - view_item", dataLayer);
    }
  }

  bikes_layer() {
    let bikesData = [];
    let bikes = document.getElementsByClassName("bike-info");
    for (let i = 0; i < bikes.length; i++) {
      let bike = JSON.parse(bikes[i].dataset.bike);
      bikesData.push({
        item_id: bike.model_id.toString(),
        item_name: bike.model_title,
        currency: "EUR",
        index: i,
        item_brand: bike.manufacturer,
        item_category: bike.product_group_name,
        price: parseFloat(bikes[i].dataset.price),
        quantity: 1,
      });
    }
    let tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_name: "Bikes",
        items: bikesData,
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - view_item_list", dataLayer);
  }

  cart_layer() {
    document.removeEventListener("priced", this.cart_layer);
    const items = document.getElementsByClassName("cart-item");
    const options = document.querySelectorAll("input.extra:checked");
    const total = document.getElementById("total");
    let tmp;
    let itemsData = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i].dataset;
      tmp = JSON.parse(item.value);
      if (item.type === "bike") {
        itemsData.push({
          item_id: tmp.modelId.toString(),
          item_name: tmp.modelName,
          currency: "EUR",
          index: i,
          item_brand: tmp.manufacturer,
          item_category: tmp.category,
          price: parseFloat(tmp.pricePerUnit),
          location_id: tmp.plocId.toString(),
          quantity: tmp.unitCount,
        });
      }
      if (item.type === "tour") {
        itemsData.push({
          item_id: tmp.tourId.toString(),
          item_name: tmp.details.tourName,
          currency: "EUR",
          index: i,
          item_category: tmp.details.categoryName,
          price: parseFloat(tmp.pricePerUnit),
          location_id: tmp.details.locId.toString(),
          quantity: tmp.unitCount,
        });
      }
    }
    for (let i = 0; i < options.length; i++) {
      let item = options[i].dataset;
      tmp = JSON.parse(item.item);
      itemsData.push({
        item_id: tmp.prodId.toString(),
        item_name: tmp.title,
        currency: "EUR",
        index: i,
        item_category: tmp.prodType,
        price: parseFloat(tmp.price),
      });
    }
    tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    let totalValue = total ? parseFloat(total.innerText) : "";
    let session = {
      currency: "EUR",
      value: totalValue,
      items: itemsData,
      enhanced_conversion_data: user,
    };
    sessionStorage.setItem("cart_layer", JSON.stringify(session));
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "view_cart",
      ecommerce: { ...session },
    });
    console.log("DATALAYER - view_cart", dataLayer);
  }

  addToCart_layer(total, items) {
    let tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(total),
        items: items,
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - add_to_cart", dataLayer);
  }

  removeFromCart_layer(total, items) {
    let tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(total),
        items: items,
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - remove_from_cart", dataLayer);
  }
}
