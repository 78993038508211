import { Controller } from "@hotwired/stimulus";
import { turbo_fetch } from "../helpers";

export default class extends Controller {
  static values = { tour: String, basket: String };

  connect() {
    this.updateBasket();
  }

  pickBike(event) {
    let button = document.getElementById("addToCart");
    let select = document.getElementById("pedal");
    select.forEach((option) => {
      select.remove(option);
    });
    let bike = JSON.parse(event.target.dataset.bike);
    let price = document.getElementById("price");
    let image = document.getElementById("image");
    button.dataset.model = bike.modelId;
    button.dataset.total = bike.price;
    price.innerHTML = bike.price;

    image.src = bike.image;
    let form = document.getElementById("form");
    if (bike.pedals) {
      form.classList.remove("hidden");
      let selected = event.target.dataset.selected;
      let pedals = bike.pedals;
      pedals.forEach((pedal) => {
        let option = document.createElement("option");
        option.text = pedal.pedalName;
        option.value = pedal.pedalId;
        if (pedal.pedalId == selected) {
          option.selected = "selected";
        }
        select.appendChild(option);
      });
    } else {
      form.classList.add("hidden");
    }

    document.getElementById("modal").classList.remove("hidden");
    document.body.classList.add("overflow-hidden");

    //document.getElementById('spinner_modal').classList.remove('hidden');
  }

  setBike(obj) {
    const lang = document.getElementById("locale").value;
    let bike = null;
    if (obj && obj.modelId) {
      bike = obj.modelId;
    } else return;
    let date = obj.date.split("/");
    date = new Date(date[2], date[1] - 1, date[0]);
    let pick = document.getElementById("select-" + bike);
    let unpick = document.getElementById("unselect-" + bike);
    let boxes = document.getElementsByClassName("bike-box");
    let box = document.getElementById("bike-" + bike);
    let tours = document.getElementById("tour-boxes");
    boxes.forEach((element) => {
      element.classList.add("hidden");
    });
    box.classList.remove("hidden");
    pick.classList.add("hidden");
    unpick.classList.remove("hidden");
    unpick.dataset.resId = obj.resId;
    let dates = document.getElementsByClassName("tour-date").length;
    for (let i = 0; i < dates; i++) {
      let elem = document.getElementById(`date-${i}`);
      let elem2 = document.getElementById(`tour-${i}`);
      elem.innerHTML = `${getWeekDay(date.getDay(),lang)} - ${date.toLocaleDateString()}`;
      elem2.dataset.date = date.toLocaleDateString();
      elem2.dataset.resId = obj.resId;
      date.setDate(date.getDate() + 1);
    }
    tours.classList.remove("hidden");
    this.updateBasket();
    this.closeModal();

    function getWeekDay(day, lang) {
      let weekDays;
      switch (lang) {
        case "es":
          weekDays = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
          ];
          break;
        case "de":
          weekDays = [
            "Sonntag",
            "Montag",
            "Dienstag",
            "Mittwoch",
            "Donnerstag",
            "Freitag",
            "Samstag",
          ];
          break;
        default:
          weekDays = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          break;
      }
      return weekDays[day];
    }
  }

  undoBike(e) {
    let bike = null;
    let resId = null;
    if (e.target.dataset && e.target.dataset.bike) {
      bike = JSON.parse(e.target.dataset.bike);
    } else return;
    let pick = document.getElementById("select-" + bike.modelId);
    let unpick = document.getElementById("unselect-" + bike.modelId);
    let boxes = document.getElementsByClassName("bike-box");
    let tours = document.getElementById("tour-boxes");
    boxes.forEach((element) => {
      element.classList.remove("hidden");
    });
    pick.classList.remove("hidden");
    unpick.classList.add("hidden");
    tours.classList.add("hidden");
    if (e.target.dataset && e.target.dataset.resId) {
      resId = JSON.parse(e.target.dataset.resId);
    }
    let basket = this.basketValue;
    let url = `/booking_process/remove_from_basket?res_id=${resId}`;
    fetch(url).then((data) => {
      this.updateBasket();
    });
  }

  pickTour(e) {
    const lang = document.getElementById("locale").value;
    let path = e.path || (e.composedPath && e.composedPath());
    path.forEach((el) => {
      if (el.dataset && el.dataset.date) {
        let tour = JSON.parse(
          document.getElementById("view-tour").dataset.info
        );
        let stringDate = formatDate(el.dataset.date);
        let date = new Date(stringDate);
        let index = el.dataset.index;
        let week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        let day = week[date.getDay()];
        let url = `/${lang}/booking_process/filter_tours?day=${day}&cat=${tour.tour_group_id}&locg_id=${tour.locg_id}&season=${tour.season_id}&index=${index}`;
        if (tour.duration - tour.num_sub_tours >= getTours(el))
          url += `&full=true`;
        turbo_fetch(url);
        fetch(url).then((response) => {
          let modal = document.getElementById("modal_list");
          modal.classList.remove("hidden");
        });
      }
    });

    function formatDate(stringDate) {
      let arrayDate = stringDate.split("/");
      let day = arrayDate[0].padStart(2, "0");
      let month = arrayDate[1].padStart(2, "0");
      return `${arrayDate[2]}-${month}-${day}`;
    }

    function getTours(element) {
      let list = element.parentNode.parentNode;
      let notSelected = list.querySelectorAll(
        ":scope > turbo-frame > div[data-action]"
      );
      return notSelected.length;
    }
  }

  addToCart(event) {
    let elem = document.getElementById("addToCart");
    elem.classList.remove("hidden");
    elem = elem.dataset;
    let tour_data = JSON.parse(elem.info);
    let errors = false;
    let name = document.getElementById("name");
    let surname = document.getElementById("surname");
    let weight = document.getElementById("weight");
    let height = document.getElementById("height");
    let pedal = document.getElementById("pedal");
    let saddle = document.getElementById("saddle");
    let date = document.getElementById("date").value;
    let modelId = event.target.dataset.model;
    let tourId = event.target.dataset.tour;
    let total = event.target.dataset.total;
    let duration = event.target.dataset.duration;
    let form = document.getElementById("form");
    let location = event.target.dataset.location;
    let spinner_modal = document.getElementById("spinner_modal");

    if (!name.value) {
      name.classList.add("error");
      errors = true;
    } else name.classList.remove("error");

    if (!surname.value) {
      surname.classList.add("error");
      errors = true;
    } else surname.classList.remove("error");

    if (errors) return true;

    name = name.value;
    surname = surname.value;

    let url = `/booking_tours/add_to_basket?name=${name}&surname=${surname}&tour=${tourId}&model=${modelId}&loc=${location}&total=${total}&date=${date}&duration=${duration}`;

    if (form && !form.classList.contains("hidden")) {
      if (weight) {
        if (weight.value) url += `&weight=${weight.value}`;
        else errors = true;
      }

      if (height) {
        if (height.value) url += `&height=${height.value}`;
        else errors = true;
      }

      if (pedal) {
        if (pedal.value) url += `&pedal=${pedal.value}`;
        else errors = true;
      }

      if (saddle) {
        if (saddle.value) url += `&saddle=${saddle.value}`;
      }

      if (errors) {
        return true;
      }
    }

    let basket = this.basketValue;

    if (basket && basket !== "undefined") url += `&basket=${basket}`;

    document.getElementById("addToCart").classList.add("hidden");
    document.getElementById("spinner-text").classList.remove("hidden");
    spinner_modal.classList.remove("hidden");

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (!data || data.unavailable || data.resId == null) {
          document.getElementById("unavailable").classList.remove("hidden");
          document.getElementById("addToCart").classList.remove("hidden");
          spinner_modal.classList.add("hidden");
          document.getElementById("spinner-text").classList.add("hidden");
        } else {

          let userInfo = document.getElementById("user-info");
          let user = {};
          if (userInfo) {
            userInfo = userInfo.dataset;
            user = {
              email: userInfo.email,
              first_name: userInfo.first_name,
              last_name: userInfo.last_name,
              phone_number: userInfo.phone_number,
              street: userInfo.street,
              city: userInfo.city,
              postal_code: userInfo.postal_code,
              country: userInfo.country,
            };
          }
          dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              currency: "EUR",
              value: parseFloat(total),
              items: [
                {
                  item_id: tour_data.tour_id.toString(),
                  item_name: tour_data.tour_name,
                  index: 0,
                  item_category: tour_data.category_name,
                  location_id: tour_data.loc_id.toString(),
                  price: parseFloat(total),
                  quantity: 1,
                },
              ],
              enhanced_conversion_data: user,
            },
          });
          console.log("DATALAYER - add_to_cart", dataLayer);
        }
        if (spinner_modal) spinner_modal.classList.add("hidden");
        document.getElementById("spinner-text").classList.add("hidden");

        let obj = {
          modelId: data.modelId,
          resId: data.resId,
          date: date,
        };
        this.setBike(obj);
      })
      .catch((error) => {
        if (spinner_modal) spinner_modal.classList.add("hidden");
        document.getElementById("spinner-text").classList.add("hidden");
        console.error(error);
        document.getElementById("addToCart").classList.remove("hidden");
      });
  }

  addToCartAgent(event) {
    let elem = document.getElementById("addToCart");
    elem.classList.remove("hidden");
    elem = elem.dataset;
    let tour_data = JSON.parse(elem.info);
    let errors = false;
    let name = document.getElementById("name");
    let surname = document.getElementById("surname");
    let weight = document.getElementById("weight");
    let height = document.getElementById("height");
    let pedal = document.getElementById("pedal");
    let saddle = document.getElementById("saddle");
    let date = document.getElementById("date").value;
    let modelId = event.target.dataset.model;
    let tourId = event.target.dataset.tour;
    let total = event.target.dataset.total;
    let duration = event.target.dataset.duration;
    let form = document.getElementById("form");
    let location = event.target.dataset.location;
    let spinner_modal = document.getElementById("spinner_modal");
    let mail = document.getElementById("rider-mail");
    let phone = document.getElementById("rider-phone");

    if (!name.value) {
      name.classList.add("error");
      errors = true;
    } else name.classList.remove("error");

    if (!surname.value) {
      surname.classList.add("error");
      errors = true;
    } else surname.classList.remove("error");

    if (!mail.value) {
      mail.classList.add("error");
      errors = true;
    } else mail.classList.remove("error");

    if (!phone.value) {
      phone.classList.add("error");
      errors = true;
    } else phone.classList.remove("error");

    if (errors) return true;

    name = name.value;
    surname = surname.value;
    phone = phone.value;
    mail = mail.value

    let url = `/agents/add_tour_to_basket?name=${name}&surname=${surname}&tour=${tourId}&model=${modelId}&loc=${location}&total=${total}&date=${date}&mail=${mail}&phone=${phone}`;

    if (form && !form.classList.contains("hidden")) {
      if (weight) {
        if (weight.value) url += `&weight=${weight.value}`;
        else errors = true;
      }

      if (height) {
        if (height.value) url += `&height=${height.value}`;
        else errors = true;
      }

      if (pedal) {
        if (pedal.value) url += `&pedal=${pedal.value}`;
        else errors = true;
      }

      if (saddle) {
        if (saddle.value) url += `&saddle=${saddle.value}`;
      }

      if (errors) {
        return true;
      }
    }

    if ( spinner_modal ) spinner_modal.classList.remove('hidden');

    document.getElementById("addToCart").classList.add("hidden");
    document.getElementById("spinner-text").classList.remove("hidden");
    spinner_modal.classList.remove("hidden");

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (!data || data.unavailable) {
          document.getElementById("unavailable").classList.remove("hidden");
          document.getElementById("addToCart").classList.remove("hidden");
          spinner_modal.classList.add("hidden");
          document.getElementById("spinner-text").classList.add("hidden");
        } else {
          
          let storedBasket = sessionStorage.getItem("baskets");
          let basketArray = storedBasket ? JSON.parse(storedBasket) : [];
          basketArray.push(data.basket);
          let updatedBasket = JSON.stringify(basketArray);
          sessionStorage.setItem("baskets", updatedBasket);

          let userInfo = document.getElementById("user-info");
          let user = {};
          if (userInfo) {
            userInfo = userInfo.dataset;
            user = {
              email: userInfo.email,
              first_name: userInfo.first_name,
              last_name: userInfo.last_name,
              phone_number: userInfo.phone_number,
              street: userInfo.street,
              city: userInfo.city,
              postal_code: userInfo.postal_code,
              country: userInfo.country,
            };
          }
          dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
          dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              currency: "EUR",
              value: parseFloat(total),
              items: [
                {
                  item_id: tour_data.tour_id.toString(),
                  item_name: tour_data.tour_name,
                  index: 0,
                  item_category: tour_data.category_name,
                  location_id: tour_data.loc_id.toString(),
                  price: parseFloat(total),
                  quantity: 1,
                },
              ],
              enhanced_conversion_data: user,
            },
          });
          console.log("DATALAYER - add_to_cart", dataLayer);
        }
        if (spinner_modal) spinner_modal.classList.add("hidden");
        document.getElementById("spinner-text").classList.add("hidden");

        let obj = {
          modelId: data.modelId,
          resId: data.resId,
          date: date,
        };
        this.setBike(obj);
      })
      .catch((error) => {
        if (spinner_modal) spinner_modal.classList.add("hidden");
        document.getElementById("spinner-text").classList.add("hidden");
        console.error(error);
        document.getElementById("addToCart").classList.remove("hidden");
      });
  }

  addSubTour(e) {
    const lang = document.getElementById("locale").value;
    let tour = e.target.dataset.tour;
    let index = e.target.dataset.index;
    let resId = document.getElementById(`tour-${index}`).dataset.resId;
    let date = formatDate(
      document.getElementById(`tour-${index}`).dataset.date
    );
    let url = `/${lang}/tours-week/${resId}/add/${index}/${tour}/${date}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (!data || data.unavailable) {
          document.getElementById("modal_list").classList.add("hidden");
          document.getElementById("unavailable").classList.remove("hidden");
          document.getElementById("modal").classList.remove("hidden");
          document.getElementById("spinner_modal").classList.add("hidden");
        } else {
          this.closeModalList();
          turbo_fetch(
            `/${lang}/tours-week/update_box/${data.index}/${data.res_id}/${data.tour_id}`
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    function formatDate(stringDate) {
      let arrayDate = stringDate.split("/");
      let day = arrayDate[0].padStart(2, "0");
      let month = arrayDate[1].padStart(2, "0");
      return `${arrayDate[2]}-${month}-${day}`;
    }
  }

  clearSubTour(e) {
    let tour = e.target.dataset.tour;
    let index = e.target.dataset.index;
    let resId = e.target.dataset.resid;
    //let resId = getButton().dataset.resId;
    let url = `/tours-week/${resId}/remove/${index}/${tour}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data && !data.unavailable) {
          let url2 = `/tours-week/update_box/${data.index}/${data.res_id}/${data.tour_id}`;
          turbo_fetch(url2);
          fetch(url2).then((response) => {
            document.getElementById(`tour-${data.index}`).dataset.date =
              document
                .getElementById(`date-${data.index}`)
                .innerHTML.split(" - ")[1];
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    function getButton() {
      var btns = document.querySelectorAll('[id^="unselect-"]');
      var shownBtn = Array.from(btns).filter(function (btn) {
        return !btn.classList.contains("hidden") && btn.dataset.resId !== "";
      });
      return shownBtn[0];
    }
  }

  closeModal(event) {
    document.getElementById("modal").classList.add("hidden");
    document.body.classList.remove("overflow-hidden");
    document.getElementById("addToCart").classList.remove("hidden");
  }

  closeModalList(event) {
    document.getElementById("modal_list").classList.add("hidden");
    document.body.classList.remove("overflow-hidden");
    //document.getElementById('addToCart').classList.remove('hidden');
    //document.getElementById('close').classList.add('hidden');
  }

  closeUnavailable(event) {
    document.getElementById("unavailable").classList.add("hidden");
  }

  updateBasket() {
    let basket = this.basketValue;
    let count = 0;
    if (basket) {
      const url = `/booking_process/get_basket/${basket}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            data.OrderPosition.forEach((element) => {
              if (element.prodType == "R" || element.prodType == "T") count++;
            });
            document.getElementById("cart1").innerHTML = count;
            document.getElementById("cart2").innerHTML = count;
          }
        });
    } else {
      let baskets = sessionStorage.getItem("baskets");
      if (baskets) {
        count = JSON.parse(baskets).length;
        document.getElementById("cart1").innerHTML = count;
        document.getElementById("cart2").innerHTML = count;
      }
    }
  }

  checkout() {
    let basket = this.basketValue;
    const lang = document.getElementById("locale").value;
    const isle = document.getElementById("current-island");
    let island = "gran-canaria";
    switch (isle.innerText) {
      case "GRAN CANARIA":
        island = "gran-canaria";
        break;
      case "TENERIFE":
        island = "tenerife";
        break;
      case "LANZAROTE":
        island = "lanzarote";
        break;
      default:
        island = "gran-canaria";
    }
    window.location.href = `/${lang}/${island}/basket`;
  }

  checkoutAgent() {
    const lang = document.getElementById("locale").value;
    const isle = document.getElementById("current-island");
    let island = "gran-canaria";
    switch (isle.innerText) {
      case "GRAN CANARIA":
        island = "gran-canaria";
        break;
      case "TENERIFE":
        island = "tenerife";
        break;
      case "LANZAROTE":
        island = "lanzarote";
        break;
      default:
        island = "gran-canaria";
    }
    window.location.href = `/${lang}/${island}/agents/baskets`;
  }

  toggleLogin(event) {
    let elem = document.getElementById("collapse");
    let elem2 = document.getElementById("session");
    elem.classList.toggle("hidden");
    elem2.classList.toggle("hidden");
  }

  showSpinner() {
    let spinner_modal = document.getElementById("spinner_modal");
    if (spinner_modal) spinner_modal.classList.remove("hidden");
  }
}
