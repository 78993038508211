import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "spinner" ]
  
  connect() { }
  
  disconnect() { 
    if (this.hasSpinnerTarget) 
      this.spinnerTarget.classList.add('hidden');
  }

  show() {
    if (this.hasSpinnerTarget) 
      this.spinnerTarget.classList.remove('hidden');
  }
}