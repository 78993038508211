import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = { basket: String };

  connect() {
    this.updateBasket()
  }

  disconnect() {
    if (this.hasBasketValue) {
      fetch(`/basket/${this.basketValue}/close`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ basket: this.basketValue })
      })
        .then(response => response.json())
        .catch(error => {
          console.error('Error closing order:', error);
        });
    }
  }

  toggleExtra(event) {
    let lang = "en";
    let basket = this.basketValue;
    const extra = event.target;
    const cancel = extra.dataset.cancel;
    let resId = extra.dataset.bike;
    let tmp = JSON.parse(extra.dataset.item);
    let add;

    if (extra.checked) {
      add = true;
      var url = `/booking_process/add_extra_to_bike?basket=${basket}&res_id=${resId}&prod_id=${extra.value}&lang=${lang}`;
    } else {
      add = false;
      var url = `/booking_process/remove_from_basket?basket=${basket}&res_id=${cancel}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let items = [
          {
            item_id: tmp.prodId.toString(),
            item_name: tmp.title,
            currency: "EUR",
            item_category: tmp.prodType,
            price: parseFloat(tmp.price),
            quantity: 1
          },
        ];
        if (add) this.addToCart_layer(parseFloat(tmp.price), items);
        else this.removeFromCart_layer(parseFloat(tmp.price), items);
        this.updateBasket();
        if (data && data.resId) event.target.dataset.cancel = data.resId;
        else delete event.target.dataset.cancel;
      });
  }

  toggleTour(event) {
    document.getElementById('spinner').classList.remove('hidden');
    let basket = event.target.dataset.basket
      ? event.target.dataset.basket
      : this.basketValue;
    const tour = event.target;
    const bike = tour.dataset.bike;
    var date = tour.dataset.date;
    const cancel = tour.dataset.cancel;
    let tours = document.querySelectorAll(`.tour-${bike}`);
    let tmp = JSON.parse(tour.dataset.item);
    let add;

    if (tour.checked) {
      tours.forEach((element) => {
        if (element.value != tour.value && date == element.dataset.date)
          element.setAttribute("disabled", "disabled");
      });
      add = true;
      var url = `/booking_process/add_tour_to_basket?basket=${basket}&tour=${tour.value}&bpr=${tour.dataset.bpr}&res=${bike}&loc=${tour.dataset.loc}&date=${date}`;
    } else {
      tours.forEach((element) => {
        if (element.value != tour.value && date == element.dataset.date)
          element.removeAttribute("disabled");
      });
      add = false;
      var url = `/booking_process/remove_from_basket?basket=${basket}&res_id=${cancel}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let items = [
          {
            item_id: tmp.tourId.toString(),
            item_name: tmp.details.tourName,
            item_category: tmp.details.categoryName,
            location_id: tmp.details.locId,
            price: parseFloat(tmp.price),
            quantity: 1,
          },
        ];
        if (add) this.addToCart_layer(parseFloat(tmp.price), items);
        else this.removeFromCart_layer(parseFloat(tmp.price), items);
        if (data && data.resId) event.target.dataset.cancel = data.resId;
        else delete event.target.dataset.cancel;
        window.location.reload();
      });
  }

  changeDriver(event) {
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((element) => {
      if (element.dataset && element.dataset.bprid) {
        let nameInput = document.getElementById("name");
        let surnameInput = document.getElementById("surname");
        let emailInput = document.getElementById("email");
        let phonelInput = document.getElementById("phone");
        let saveDriver = document.getElementById("save-driver");
        saveDriver.dataset.bprid = element.dataset.bprid;
        const url = `/agents/get_customer?bprId=${element.dataset.bprid}`;
        document.getElementById('spinner').classList.remove('hidden');
        fetch(url)
        .then((response) => response.json())
        .then((data) => {
          nameInput.value = data.firstname;
          surnameInput.value = data.name;
          emailInput.value = data.email;
          phonelInput.value = data.mobil;
          document.getElementById('spinner').classList.add('hidden');
          document.getElementById('driver-modal').classList.remove('hidden');
        })
        .catch(function (error) {
          document.getElementById('spinner').classList.add('hidden');
          console.log("Hubo un problema con la petición:" + error.message);
        });;
      }
    });
  }

  closeDriver() {
    document.getElementById('driver-modal').classList.add('hidden');
    let nameInput = document.getElementById("name");
    let surnameInput = document.getElementById("surname");
    let saveDriver = document.getElementById("save-driver");
    nameInput.value = '';
    surnameInput.value = '';
    saveDriver.dataset.bprid = '';
  }

  saveDriver(event) {
    const nameInput = document.getElementById("name").value;
    const surnameInput = document.getElementById("surname").value;
    const emailInput = document.getElementById("email").value;
    const phoneInput = document.getElementById("phone").value;
    const url = `/agents/change_driver?bprId=${event.target.dataset.bprid}&name=${nameInput}&surname=${surnameInput}&email=${emailInput}&phone=${phoneInput}`;
    document.getElementById('spinner').classList.remove('hidden');
    document.getElementById('driver-modal').classList.add('hidden');
    fetch(url).then((data) => {
      window.location.reload();
    });
  }

  removeFromCart(event) {
    document.getElementById("spinner").classList.remove("hidden");
    let basket = this.basketValue;
    let cancel = "";
    let tmp;
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((element) => {
      if (element.dataset && element.dataset.cancel) {
        cancel = element.dataset.cancel;
        tmp = JSON.parse(element.dataset.item);
      }
    });
    if (cancel.length > 0) {
      let items;
      if (tmp.modelId) {
        items = [
          {
            item_id: tmp.modelId.toString(),
            item_name: tmp.modelName,
            item_brand: tmp.manufacturer,
            item_category: tmp.category,
            location_id: tmp.plocId,
            price: parseFloat(tmp.pricePerUnit),
            quantity: tmp.unitCount,
          },
        ];
      } else {
        items = [
          {
            item_id: tmp.tourId.toString(),
            item_name: tmp.details.tourName,
            item_category: tmp.details.categoryName,
            location_id: tmp.details.locId,
            price: parseFloat(tmp.pricePerUnit),
            quantity: 1,
          },
        ];
      }
      let url = `/booking_process/remove_from_basket?basket=${basket}&res_id=${cancel}`;
      fetch(url).then((data) => {
        this.removeFromCart_layer(parseFloat(tmp.priceTotal), items);
        window.location.reload();
      });
    }
  }

  updateBasket() {
    if (document.getElementById("price_spinner")) document.getElementById("price_spinner").classList.remove("hidden");
    let basket = this.basketValue;
    if (basket && basket != 'null') {
      const url = `/booking_process/get_basket/${basket}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          let unavailable = document.getElementById("unavailable");
          if (unavailable) unavailable.classList.add("hidden");
          let count = 0;
          let price = 0;
          let discount = 0;
          let sum = 0;
          if (data) {
            data.OrderPosition.forEach((element) => {
              if (element.navItemNo != '145A')
                price = price + element.pricePerUnit;
              if (element.prodType == "R" || element.prodType == "T") count++;
              if (element.pricePerUnit < 0 && element.navItemNo != '145A')
                discount = discount + Math.abs(element.pricePerUnit);
            });
          }
          let supertotal = document.getElementById("supertotal");
          let superdiscount = document.getElementById("discount");
          let wrapper = document.getElementById("discount-wrapper");
          if (discount > 0) {
            sum = price + discount;
            sum = sum.toFixed(2);
            sum = sum.replace(".", ",");
            price = price.toFixed(2);
            price = price.replace(".", ",");
            discount = discount.toFixed(2);
            discount = discount.replace(".", ",");
            if (supertotal) {
              supertotal.innerHTML = sum + "€";
              supertotal.classList.remove("hidden");
            }
            if (superdiscount) {
              superdiscount.innerHTML = discount + "€";
              wrapper.classList.remove("hidden");
            }
          } else {
            if (supertotal) supertotal.classList.add("hidden");
            if (wrapper) wrapper.classList.add("hidden");
          }
          let total = document.getElementById("total");
          if (total) total.innerHTML = price;

          const event = document.createEvent("Event");
          event.initEvent("priced", true, true);
          document.dispatchEvent(event);
          document.getElementById("spinner").classList.add("hidden");
          if (document.getElementById("price_spinner"))
            document.getElementById("price_spinner").classList.add("hidden");
        });
    } else {
      document.getElementById("spinner").classList.add("hidden");
    }
  }

  updateBooking() {
    document.getElementById("spinner").classList.remove("hidden");
    let total = document.getElementById("total").innerHTML;
    const id = document.getElementById("booking-id").value;
    let url = `/bookings/${id}/edit?amount=${total}`

    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ amount: total })
    })
    .then(response => response.json())
    .then(data => {
      if (data.redirect) {
        window.location.href = data.redirect;
      }
    })
    .catch(error => console.error('Error:', error));
  }

  paySingleBooking(event) {
    const spinner = document.getElementById("spinner")
    if(spinner) spinner.classList.remove("hidden");
    const e = event.target;
    const basket = e.dataset.basket;
    let url = `/booking_process/create-payment?basket=${basket}`;

    fetch(url)
      .then((response) => {
        if (response.redirected) {
          window.location.href = response.url;
          return;
        }
        return response.json();
      })
      .then((data) => {
        if(spinner) spinner.classList.add("hidden");
        if (data && data.ref) {
          window.location = `https://www.vivapayments.com/web/checkout?ref=${data.ref}`;
        } else {
          alert("An error has occurred with the payment platform. Please try again.");
        }
      })
      .catch((error) => {
        if(spinner) spinner.classList.add("hidden");
        console.error('Error:', error);
        alert("An error has occurred with the payment platform. Please try again.");
      });
  }


  addToCart_layer(total, items) {
    let tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(total),
        items: items,
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - add_to_cart", dataLayer);
  }

  removeFromCart_layer(total, items) {
    let tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(total),
        items: items,
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - remove_from_cart", dataLayer);
  }
}